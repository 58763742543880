<template>
  <div class="icon-bg" :class="{ 'icon-bg_active': isShowNotificationModal }">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5005 22C13.6005 22 14.5005 21.1 14.5005 20H10.5005C10.5005 21.1 11.3905 22 12.5005 22ZM18.5005 16V11C18.5005 7.93 16.8605 5.36 14.0005 4.68V4C14.0005 3.17 13.3305 2.5 12.5005 2.5C11.6705 2.5 11.0005 3.17 11.0005 4V4.68C8.13054 5.36 6.50054 7.92 6.50054 11V16L5.21054 17.29C4.58054 17.92 5.02054 19 5.91054 19H19.0805C19.9705 19 20.4205 17.92 19.7905 17.29L18.5005 16Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconAdmin",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("Notification", ["isShowNotificationModal"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;

  svg {
    width: 24px;
    height: 24px;
  }

  &_active {
    background: #ebf2fa;

    svg {
      path {
        fill: #4f52f4;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #4f52f4;
        }
      }
    }
  }

  &:active {
    background: transparent;

    svg {
      rect {
        fill: #5551f9;
      }
    }
  }
}
</style>
